import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// import mui components
import { Typography } from "@material-ui/core"

// import app components
import BackgroundImage from "components/backgroundImage"

// import * as theme from "theme"

const PostTile = props => {
  const { post, isHero, ...rest } = props
  const { title, uri, acfPost } = post || {}
  const { featuredImage } = acfPost || {}

  return (
    <Container to={uri} {...rest} isHero={isHero}>
      {featuredImage?.localFile?.childImageSharp && (
        <ImageContainer isHero={isHero}>
          <BackgroundImage image={featuredImage} />
        </ImageContainer>
      )}

      <Title>{title}</Title>
    </Container>
  )
}

export default PostTile

const Container = styled(Link)`
  text-decoration: none;
  position: relative;
  margin-bottom: 30px;
  color: inherit;
  display: block;
  width: 100%;

  @media (min-width: 800px) {
    width: ${({ isHero }) => (isHero ? "100%" : "calc(50% - 20px)")};
  }

  ${({ dummy }) =>
    dummy &&
    css`
      height: 0;
      margin: 0;
    `}

  &:hover {
    text-decoration: none;
  }
`

const Title = styled(Typography)`
  && {
    font-weight: 500;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  margin-bottom: 5px;

  @media (min-width: 800px) {
    height: ${({ isHero }) => (isHero ? "1200px" : "600px")};
  }

  @media (min-width: 1024px) {
    height: ${({ isHero }) => (isHero ? "1600px" : "800px")};
  }

  .hovered {
    transition: ease all 0.4s;
    opacity: 0;
  }

  &:hover {
    .featured {
      opacity: 1;
    }

    .hovered {
      opacity: 1;
    }
  }
`
