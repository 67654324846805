import React from "react"
import styled from "styled-components"

import Edges from "components/edges"
import PostTile from "./PostTile"

const PostsGrid = props => {
  const { posts, ...rest } = props

  return (
    <Container {...rest}>
      <Edges size="lg">
        {posts && (
          <Items>
            {posts.map((post, i) => {
              const isHero = (i + 1) % 3 === 0

              return <PostTile key={post.id} post={post} isHero={false} />
            })}
            <PostTile dummy />
            <PostTile dummy />
            <PostTile dummy />
            <PostTile dummy />
          </Items>
        )}
      </Edges>
    </Container>
  )
}

export default PostsGrid

const Container = styled.div``

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`
