import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import app components
import { Socials, FlexibleContent } from "components"

import PostsGrid from "components/postsGrid"

const PostsArchive = props => {
  const {
    data: {
      page: {
        title,
        uri,
        acf: { modules },
      },
      allWpPost,
    },
  } = props

  return (
    <>
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri,
          }}
        />
      )}

      {allWpPost?.nodes && <PostsGrid posts={allWpPost.nodes} />}

      <SocialsContainer>
        <Socials />
      </SocialsContainer>
    </>
  )
}

export default PostsArchive

const SocialsContainer = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
`

export const CollectionQuery = graphql`query PostsArchiveQuery($id: String!) {
  page: wpPage(id: {eq: $id}) {
    ...PageFragment
  }
  allWpPost(sort: {fields: date, order: DESC}) {
    nodes {
      id
      title
      date
      uri
      acfPost {
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
